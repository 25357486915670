<template>
  <div class="commodityInfo">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left" />
        <span>产品申请</span>
      </div>
    </div>
    <div class="content">
      <van-form>
        <div class="main-part-item">
          <div class="item-head1" style="flex-direction: column;justify-content: flex-start;align-items: flex-start;">
            <div class="title">确认机构信息</div>
            <div class="rests"> 如机构信息有误请返回确认机构码</div>
          </div>
          <div class="item-body">
            <van-field
              readonly
              clickable
              name="product_name"
              :value="product_name"
              label="项目名称"
              placeholder="点击选择项目"
              @click="showProductName = true"
              input-align="right"
            />
            <van-field
              v-model="product_price"
              name="product_price"
              label="项目价格"
              placeholder="项目价格"
              input-align="right"
              readonly
            />
          </div>
        </div>
        <div class="main-part-item">
          <div class="item-head">
            <span class="title">填写信息</span>
          </div>
          <div class="item-body">
            <van-field
              v-model="submitData.price"
              name="price"
              label="￥"
              :disable="true"
              label-width="20"
              type="number"
              placeholder="请输入项目"
            />
          </div>
          <div class="item-body">
            <van-field
              v-model="submitData.name"
              name="name"
              label="姓名"
              :disable="true"
              label-width="60"
              type="text"
              @input="onNameInput"
              placeholder="请输入姓名"
            />
          </div>
        </div>
        <div style="margin: 16px">
          <div
            class="next-step-button"
            @click="onSubmit"
            v-if="productEmpty"
          >
            支付
          </div>
          <div v-else class="next-step-button">支付</div>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showProductName" position="bottom">
      <van-picker
        show-toolbar
        value-key="product_name"
        :columns="product_list"
        :default-index="productIndex"
        @confirm="onProductConfirm"
        @cancel="showProductName = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
// import { tmpSaveApplication } from "@/api/application.js";
import { applyFastBill } from '@/api/bill.js'

export default {
  name: "commodityInfo",
  props: ["tmp_save_id", "business_id", "prestore_form", "businessInfo"],
  data() {
    return {
      submitData: {
        price: "",
        name: ""
      },
      // 项目名称
      product_name: "",
      showProductName: false,
      product_list: [],
      productEmpty: false,
      productIndex: 0,
      // 项目价格
      product_price: "",
      // 输入价格范围
      priceEmpty: false,
      // 付款方式
      repay_type_text: "",
      showRepayType: false,
      repay_type: [],
      repayTypeEmpty: false,
      repayIndex: 0,
      // 分期期限
      package_name: "",
      showPackage: false,
      package_list: [],
      package_lists: [],
      packageEmpty: false,
      packageIndex: 0,
      // 每期应还
      showStagesPreview: false,
      stagesPreviewList: {},
      stage_list: [],
      skip_contact: 0
      // loading:false,
    };
  },
  mounted() {
    this.initData()
  },
  methods: {
    onNameInput(value) {
      this.submitData.name = value;
    },
    async initData() {
      this.skip_contact = this.businessInfo.skip_contact;
      this.product_list = this.businessInfo.product_list;
      this.package_list = this.businessInfo.package_list;
      var averageList = [];
      var combinationList = [];
      this.package_list.map((item) => {
        if (item.repay_type == 1) {
          averageList.push(item);
        } else if (item.repay_type == 2) {
          combinationList.push(item);
        }
      });
      if (averageList.length == 0) {
        this.businessInfo.repay_type.splice(
          this.businessInfo.repay_type.findIndex((item) => item.id === 1),
          1
        );
      }
      if (combinationList.length == 0) {
        this.businessInfo.repay_type.splice(
          this.businessInfo.repay_type.findIndex((item) => item.id === 2),
          1
        );
      }
      this.repay_type = this.businessInfo.repay_type;

      console.log(this.prestore_form);
      var data = this.prestore_form;
      if (JSON.stringify(data) != "{}") {
        this.submitData = data;
        var productObj = this.product_list.find((obj, index) => {
          if (obj.product_id == data.productId) {
            this.productIndex = index;
          }
          return obj.product_id == data.productId;
        });
        this.product_name = productObj.product_name;
        this.product_price = productObj.price;
        var repayObj = this.repay_type.find((obj, index) => {
          if (obj.id == data.repay_type) {
            this.repayIndex = index;
          }
          return obj.id == data.repay_type;
        });
        this.repay_type_text = repayObj.name;

        var packageList = [];
        this.package_list.map((obj) => {
          if (obj.repay_type == data.repay_type) {
            packageList.push(obj);
          }
        });
        this.package_lists = packageList;
        var packageObj = this.package_lists.find((obj, index) => {
          if (obj.package_id == data.packageId) {
            this.packageIndex = index;
          }
          return obj.package_id == data.packageId;
        });
        this.package_name = packageObj.repay_period_num_txt;
        this.productEmpty = true;
        this.priceEmpty = true;
        this.repayTypeEmpty = true;
        this.packageEmpty = true;
      }
    },
    async onSubmit() {
      var that = this;
      that.loading = true;
      // const parmes = {
      //   ...that.submitData,
      //   tmp_save_id: this.tmp_save_id,
      //   step: 1,
      // };
      const parmes = {
        productID: that.submitData.productId,
        price: that.submitData.price,
        username: that.submitData.name
      };
      console.log(parmes);
      const {code, data} = await applyFastBill(parmes)
      if (code == 200) {
        console.log(data);
        this.$router.push({
        name: 'schedulePayment',
        query:{
          bill_stage_id:data.bill_stage_id
        }
      });
        that.loading = false;
      } else {
        that.loading = false;
      }
      that.loading = false;
    },
    onProductConfirm(value) {
      this.product_name = value.product_name;
      this.product_price = value.price;
      this.submitData.price = value.price
      this.submitData.productId = value.product_id;
      this.showProductName = false;
      this.productEmpty = true;
    },
    onPriceBlur() {
      var price = this.submitData.price;
      console.log(price);
      if (Number(price) > Number(this.product_price)) {
        this.priceEmpty = false;
        Toast("输入金额不能超出范围");
      } else if (Number(price) < 1000) {
        this.priceEmpty = false;
        Toast("输入金额不能低于1000");
      } else {
        this.priceEmpty = true;
      }
    },
    onClickLeft() {
      // this.$router.push("/");
      this.$router.push({
        path: "/",
        query: {
          business_id: this.business_id || "",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commodityInfo {
  height: 100%;
  width: 100%;
  background: #000000;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    background: #000000;
    font-size: 30px;
    width: 100%;
    color: #FFFFFF;
    border-top: 1px solid #e7e7e7;
    height: 100px;
  }
  .content {
    padding: 30px;
    .main-part-item {
      margin-bottom: 32px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 32px;
      .item-head1 {
        display: flex;
        align-items: center;
        background-image: url("../../../assets/img/commodity-info_bg1.png");
        background-size: cover;
        padding: 28px 0px 24px 40px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #552C00;
          line-height: 46px;
        }
        .rests {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #552C00;
          line-height: 46px;
        }
      }
      .item-head {
        display: flex;
        align-items: center;
        background: linear-gradient(115deg, #FFE5CC 0%, #FFC191 100%);
        border-radius: 32px 32px 0 0 ;
        padding: 28px 0px 24px 40px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #552C00;
          line-height: 46px;
        }
        .rests {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #552C00;
          line-height: 46px;
        }
      }
      .item-body {
        background: #FFFFFF;
        border-radius: 32px;
        padding: 25px 0px;
        margin-top: -15px;
        .field {
          padding: 0.3rem 0.4rem;
          font-size: 28px;
          display: flex;
          .label-text {
            color: #646566;
            flex: 1;
          }
          .operate {
            color: #666;
          }
        }
      }
    }
    .next-step-button {
      width: 622px;
      height: 98px;
      background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);
      border-radius: 49px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .stage-content {
    padding: 0px 30px;
    .item {
      display: flex;
      align-items: center;
      color: #333;
      padding: 20px;
      .item-date {
        flex: 1;
        .current-stage {
          font-size: 34px;
        }
        .repayment-date {
          color: #999999;
        }
      }
      .item-price {
        font-size: 34px;
      }
    }
  }
}
</style>