import ajax from '@/utils/ajax.js'

// 获取商家权限信息 跳过绑卡 跳过OCR
 export function previlegeInfoBusiness(params) {
  return ajax.post('/api/previlegeInfoBusiness',params)
}

// 获取商家反馈信息
export function businessFeedBackInfo(params) {
  return ajax.post('/api/businessFeedBackInfo',params)
} 